import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { header as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    checkShouldUseStickyHeader: (activeMQ) => (
      ['XS', 'S', 'M', 'L'].includes(activeMQ)
    ),
  },
  h: {
    checkShouldUseStickyHeader: (activeMQ, userIsIdentified) => (
      userIsIdentified ? ['M', 'L'].includes(activeMQ) : ['XS', 'S', 'M', 'L'].includes(activeMQ)
    ),
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
