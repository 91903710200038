import React from 'react';

/* Images */
import favoriteIconSvg from 'org/assets/icons/favorite.inline.svg';
import hiwExploreImage from 'org/assets/hiw/hiw_home_explore.png';
import hiwClickImage from 'org/assets/hiw/hiw_home_click.png';
import hiwShopImage from 'org/assets/hiw/hiw_home_shop.png';
import hiwEarnImage from 'org/assets/hiw/hiw_home_earn.png';
import homeButtonImage from 'org/assets/home_button.png';
import congratsImage from 'org/assets/onboarding/checkmark_green.svg';
import congratsDesktopImage from 'org/assets/onboarding/checkmark_blue.svg';

/* Components */
import ButtonScrollingModalContent
  from 'org/modules/Modal/components/ButtonScrollingModal/components/ButtonScrollingModalContent';

export const favoritesIcon = {
  defaults: {
    favoriteIconViewBox: '0 0 47 41',
    FavoriteIconSvg: favoriteIconSvg,
  },
};

export const searchBar = {
  defaults: {
    getPlaceholder: () => ('Find a Store or Product'),
    shouldUseHybridSearch: true,
  },
};

export const hybridSearchForm = {
  defaults: {
    searchButtonClassName: 'mn_usaaSVGIconSearch',
  },
};

export const hybridQuickSearch = {
  defaults: {
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
    directMerchantName: 'Save with USAA Perks Offer',
  },
};

export const searchModal = {
  defaults: {
    placeholderLanguage: '',
    shouldUseHybridSearch: true,
  },
};

export const headerExtraRewards = {
  defaults: {
    viewAllLanguage: 'View All Stores',
    getTitle: (earnType) => `Stores Offering Extra ${earnType}`,
  },
};

export const recentStores = {
  defaults: {
    viewData: {
      footerCTATitle: 'View All Stores',
    },
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const similarStores = {
  defaults: {
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const navCategoryFeatured = {
  defaults: {
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const navCategoryFlyout = {
  defaults: {
    shouldShowMerchantName: true,
    rebateOptions: {
      showRebateInline: true,
    },
  },
};

export const navCategoriesTOF = {
  defaults: {
    secTitle: 'Shop by Category',
  },
};

export const navSeasonalFlyout = {
  defaults: {
    getLinkTitle: (extraRewardsPageTitle) => `View all ${extraRewardsPageTitle}`,
  },
};

export const quickSearch = {
  defaults: {
    isPremierRebate: true,
    directMerchantName: 'Save with USAA Perks Offer',
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      elevation: false,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
  },
};

export const quickLinksTray = {
  defaults: {
    extraRewardsLanguage: 'Extra Rewards',
  },
};

export const howItWorks = {
  defaults: {
    hiwItems: [
      {
        image: hiwExploreImage,
        title: 'Explore',
        description: ({ storeCount }) => (
          <>Explore over {storeCount} stores, exclusive offers and bonus promotions, all in one place.</>
        ),
      },
      {
        image: hiwClickImage,
        title: 'Click',
        description: () => (
          <>Click on any store or offer and you'll be taken to the store's website.</>
        ),
      },
      {
        image: hiwShopImage,
        title: 'Shop',
        description: () => (
          <>Shop like you normally do and pay with any credit card — no special card needed.</>
        ),
      },
      {
        image: hiwEarnImage,
        title: 'Earn',
        description: () => (
          <>The cash back you earn will be sent to you via USAA Quarterly Check!</>
        ),
      },
    ],
    getVideoLinkText: (userIsIdentified) => (userIsIdentified ? '' : 'Watch how it works »'),
    checkShouldShowVideoButton: (userIsIdentified) => (!userIsIdentified),
    shouldShowVideoPlayButton: true,
    getSectionTitleText: (userIsIdentified) => (
      userIsIdentified
      ? 'How It Works'
      : 'Join For Free And Watch The Cash Back Add Up'
    ),
    checkShouldUseCarousel: (userIsIdentified) => (userIsIdentified),
    videoId: '5nwBtPC4xdW3uRSo9BMqtY',
    videoPlayer: 'vidyard',
    videoTitle: 'Earn Cash Back on Everyday Shopping with MemberShop',
  },
};

export const letterboxTOF = {
  defaults: {
    expiresPrefix: 'Valid thru ',
    getCouponCodeLabelText: (isMobileMQ) => (
      isMobileMQ ? 'Use code: ' : 'Shop with code: '
    ),
    useSliderCounterOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      showAdditionalElevationPrefix: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
    slick: {
      responsive: [
        {
          breakpoint: 610,
          settings: {
            variableWidth: true,
            centerMode: true,
            centerPadding: '10px',
            dots: false,
            arrows: false,
            focusOnSelect: false,
          },
        },
        {
          breakpoint: 440,
          settings: {
            variableWidth: true,
            centerMode: true,
            centerPadding: '0px',
            dots: false,
            arrows: false,
            focusOnSelect: false,
          },
        },
      ],
    },
  },
};

export const merchantTile = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
    },
  },
};

export const merchantWithOfferCountTile = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
    },
  },
};

export const merchantOffer = {
  defaults: {
    getCopyButtonText: (isMobileMQ) => (
      isMobileMQ ? 'Copy' : 'Copy code'
    ),
    getExpiresText: (isMobileMQ) => (
      isMobileMQ ? 'Valid thru ' : 'Offer valid through '
    ),
    getCopyButtonDisclaimerText: (isMobileMQ) => (
      isMobileMQ ? 'Copy and paste this code at checkout.' : 'Copy and paste this code and use at checkout.'
    ),
    rebateOptions: {
      hideElevationCurrency: false,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const featuredDeals = {
  defaults: {
    rebateDesktopOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
    rebateMobileOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const infoBar = {
  defaults: {
    infoBarAccordion: {
      rebateOptions: {
        hideElevationCurrency: false,
        hideRebateCurrencyPrefixInWas: true,
        tiered: {
          hideRebateCurrencyPrefixInWas: true,
        },
      },
    },
  },
};

export const flipTile = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
    },
  },
};

export const stickyCtaBar = {
  defaults: {
    useStickyBehaviorOnMediaQueriesOnly: ['XL', 'XXL'],
    viewOptions: {
      title: <>Turn Your Shopping into Cash Back Today</>,
      buttonLanguage: 'Log In',
    },
  },
};

export const popularOffers = {
  h: {
    slick: {
      dots: true,
      centerMode: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: false,
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    },
  },
};

export const testimonials = {
  defaults: {
    testimonialsCopy: [
      '"MemberShop is an easy way to be rewarded for the shopping you\'re going to do anyway. Convenient and ' +
      'financially beneficial.... no-brainer."',
      '"MemberShop is great. I save money on what I would buy anyway."',
      '"Easy to use... great to earn some rewards while doing normal web shopping."',
    ],
    checkShouldShowIconInCarousel: (activeMQ) => (['XS', 'S'].includes(activeMQ)),
    checkShouldShowIconInSlide: (activeMQ) => (['M', 'L', 'XL', 'XXL'].includes(activeMQ)),
    checkShouldUseCarousel: (userIsIdentified) => (!userIsIdentified),
    shouldUseRewardCountValue: false,
    getSectionTitle: (rewardCount, earnType) =>
      (<>Join Millions <span className="mn_minor">of</span> members earning {earnType}</>),
    slick: {
      slidesToShow: 1,
      centerMode: true,
      arrows: true,
      dots: true,
      autoplay: true,
      centerPadding: '0px',
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            arrows: false,
          },
        },
      ],
    },
  },
};

export const trendingAndSeasonalOffers = {
  defaults: {
    getTitle: () => <>More ways <span className="mn_minor">to</span> earn cash back</>,
  },
};

export const earningsSnapshot = {
  defaults: {
    ctaText: 'Go to My Account',
    shouldShowEarnType: false,
    showLifetimeEarningFormatted: false,
    formatRebate: (rebate) => `$${rebate % 1 !== 0 ? rebate.toFixed(2) : rebate}`,
  },
};

export const topStores = {
  defaults: {
    useCarouselOnMediaQueriesOnly: ['M', 'L', 'XL', 'XXL'],
    viewAllLinkCTAText: 'See all stores',
    getViewAllLinkUrl: (siteUrl) => `${siteUrl}/b____.htm`,
  },
};

export const giftGuide = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const stickyFooter = {
  defaults: {
    stickyFooterMobile: {
      rebateOptions: {
        hideElevationCurrency: false,
        hideRebateCurrencyPrefixInWas: true,
        tiered: {
          hideRebateCurrencyPrefixInWas: true,
        },
      },
    },
    stickyFooterDesktop: {
      rebateOptions: {
        hideElevationCurrency: false,
        hideRebateCurrencyPrefixInWas: true,
        tiered: {
          hideRebateCurrencyPrefixInWas: true,
        },
      },
    },
  },
};

export const marketingMerchantsHome = {
  defaults: {
    secTitlePill: 'Extra Cash Back',
  },
};

export const adBlockWarning = {
  defaults: {
    getWarningMessage: () => (
      `The use of ad blockers may prevent you from earning cash back on your shopping. While shopping with
      USAA MemberShop<sup>&reg;</sup>, turn off any ad blockers or use a different browser
      to ensure you earn cash back as expected.`
    ),
  },
};

export const firstFavoriteModal = {
  defaults: {
    titleText: 'You Picked A Favorite Store',
    getBodyText: (siteUrl) => (
      <>
        Keep picking your favorite stores and we'll email you when they offer extra cash back.
        Manage alert preferences at
        {' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>My Account</a>.
      </>
    ),
    buttonText: 'Okay',
  },
};

export const buttonScrollingModal = {
  defaults: {
    components: {
      ButtonScrollingModalContent,
    },
  },
};

export const merchantLogos = {
  h: {
    slick: {
      variableWidth: true,
      centerMode: true,
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S', 'M', 'L'],
  },
};

export const homeButtonBenefits = {
  defaults: {
    buttonHeader: 'Earn Cash Back With The Push Of A Button',
    buttonDescription: `Add the button browser extension for Chrome and you'll 
    get notifications while shopping so you never forget to earn cash back. Plus, you can:`,
    buttonCopy: [
      'Automatically apply coupons at checkout',
      'Find new stores offering cash back per dollar',
      'Get the lowest price with comparison features',
    ],
    buttonImage: homeButtonImage,
  },
};

export const featuredOffersLogo = {
  defaults: {
    getTitle: (storeCount) => <>Earn At {storeCount}+ Stores</>,
  },
};

export const onboardingWelcome = {
  defaults: {
    getWelcomeText: (storeCount) => (`Here are a few tips for earning the most
      cash back when you shop online at ${storeCount}+ stores.`),
    welcomeCTAText: 'Next',
  },
};

export const onboardingEmail = {
  defaults: {
    getOptInText: (earnType) => (
      <>Don't hunt for sales or {earnType} offers. We'll email them to you.</>
    ),
  },
};

export const onboardingFavorites = {
  defaults: {
    slick: {
      infinite: false,
    },
  },
};

export const onboardingButton = {
  defaults: {
    viewData: {
      getButtonHeader: () => (
        <>Earn Cash Back With The Push Of A Button</>
      ),
      getButtonDescription: () => (
        <>Get the new MemberShop Button and never miss out on cash back.</>
      ),
      rewardsType: () => (
        <>cash back</>
      ),
    },
  },
};

export const onboardingMobileCongratulation = {
  defaults: {
    image: congratsImage,
    getCongratsText: () => ('You\'re all set to start shopping and earn cash back.'),
  },
};

export const headerOnboardingCongratulations = {
  defaults: {
    image: congratsDesktopImage,
    getCongratulationText: () => ('You\'re all set to start shopping and earning cash back.'),
  },
};

export const emailOptInSubscribeModal = {
  defaults: {
    earnType: 'Cash Back',
    cancelText: 'No thanks. I don\'t like earning extra cash back',
    emailOptions: {
      email_news_optin: 1,
      email_deals_optin: 1,
      email_global_optout: 0,
    },
  },
};

export const pickFavorites = {
  defaults: {
    getTitle: () =>
      <>Get alerts when your favorite stores <span className="mn_minor">are</span> offering extra rewards</>,
  },
};

export const marketingBanner = {
  defaults: {
    desktopBannerConfig: {
      api: {
        params: {
          content_group_id: 5672,
        },
      },
    },
  },
  sr: {
    desktopBannerConfig: {
      api: {
        params: {
          content_group_id: 34741,
        },
      },
    },
    mobileBannerConfig: {
      api: {
        params: {
          content_group_id: 34743,
        },
      },
    },
  },
};

export const hybridStores = {
  defaults: {
    label: 'Extra Cash',
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const hybridProducts = {
  defaults: {
    extraLabel: 'EXTRA',
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const productDetailsModal = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const hybridCoupons = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const couponSearchResults = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};

export const productSearchResults = {
  defaults: {
    extraLabel: 'EXTRA',
    rebateOptions: {
      hideElevationCurrency: false,
      hideRebateCurrencyPrefixInWas: true,
      tiered: {
        hideRebateCurrencyPrefixInWas: true,
      },
    },
  },
};
