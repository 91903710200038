import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

// A syntax sugar for a Loadable plugin call
// Note: the module path system.import can't be fully set as an expression
// so the ./pages folder is hardcoded here to define the correct context for webpack
const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

// Note: pageComponentPath should be related to the ./pages folder
const routesConfig = [
  { name: 'home', route: '(/|/h____.htm)', pageComponentPath: LoadableWrapper('/Home') },
  { name: 'index', route: '/index.php', pageComponentPath: Index },
  { name: 'hybrid search results', route: '/hsr____.htm', pageComponentPath: LoadableWrapper('/HybridSearchResults') },
  { name: 'stores search results', route: '/msr____.htm', pageComponentPath: LoadableWrapper('/StoresSearchResults') },
  { name: 'coupon search results', route: '/csr____.htm', pageComponentPath: LoadableWrapper('/CouponSearchResults') },
  { name: 'product search results', route: '/sr____.htm', pageComponentPath: LoadableWrapper('/ProductSearchResults') },
];

// Route to the correct page if is passed as a query parameter (e.g. /index.php?p=h)
function Index({ location }) {
  const queryParams = getParamsFromSearch(location.search);
  const { p: pageName } = queryParams;

  switch (pageName) {
    case 'h': {
      const Home = LoadableWrapper('/Home');
      return <Home />;
    }
    default: {
      return null;
    }
  }
}

function Routes() {
  return (
    <Switch>
      {routesConfig.map(({ name, route, pageComponentPath }) =>
        <Route key={name} exact path={route} component={pageComponentPath} />)}
      <Route component={LoadableWrapper('/Error')} />
    </Switch>
  );
}

export default Routes;
